import {Row, Col, Image} from 'react-bootstrap';

import imgBody2Src from "../assets/images/body-2.jpeg";
import agristoreLogo from "../assets/images/agristore-azul.svg";
import legacybankLogo from "../assets/images/legacybank-logo.png";
import zoopLogo from "../assets/images/zoop-logo.png";

function CrediarioRapido() {
  return (
    <Row>
      <Col className='DescriptionText' xs={{span: 12, order: 2}} sm={{ span: 6, order: 1 }}>
        <h2 className='mb-4'>Crediário Rápido</h2>
        <p>Compre no Boleto parcelado nas lojas parceiras da AgriSwap. Isso mesmo! Boleto parcelado online sem burocracia!</p>
        <Row>
          <Col className='d-flex justify-content-center' xs={12} sm={4}>
            <Image fluid src={agristoreLogo} alt="AgriStore logo" width={108} />
          </Col>
          <Col fluid className='d-flex justify-content-center' xs={12} sm={4}>
            <Image src={legacybankLogo} alt="LegacyBank logo" width={108} />
          </Col>
          <Col fluid className='d-flex justify-content-center' xs={12} sm={4}>
            <Image src={zoopLogo} alt="Zoop logo" width={108} />
          </Col>
        </Row>
      </Col>
      <Col className='mb-5 text-right' xs={{span: 12, order: 1}} sm={{ span: 6, order: 2 }}>
        <Image src={imgBody2Src} alt="Crediário Rápido" fluid />
      </Col>
    </Row>
  )
}

export default CrediarioRapido;