import logoGreenSrc from '../assets/images/agriswap-green-logo.png';
import { Container, Navbar } from 'react-bootstrap';

function NavBar() {
  return (
    <div className="Navbar fixed-top">
      <Navbar bg="light">
        <Container className='d-flex justify-content-center'>
          <Navbar.Brand href="#home">
            <img
              src={logoGreenSrc}
              height="30"
              className="d-inline-block align-top"
              alt="AgriSwap logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavBar;