import {FaRegClock, FaMapMarkerAlt, FaGlobe, FaRegEnvelope, FaPhoneAlt} from 'react-icons/fa';

function FooterContact() {
  return (
    <div className="FooterContact">
      <h3>Contato</h3>
      <ul className='list-unstyled'>
        <li><a href='https://goo.gl/maps/Vq7jGaCRtj6GAxPX6' target='_blank' rel="noreferrer"><FaMapMarkerAlt/><span>Rua das Tamareiras, 204 W, Centro, Sala 07, Nova Mutum - MT</span></a></li>
        <li><FaRegClock/><span>9:00am - 15:00pm</span></li>
        <li><FaGlobe/><span>agriswap.com.br</span></li>
        <li><a href='mailto:contato@agriswap.com.br'><FaRegEnvelope/><span>contato@agriswap.com.br</span></a></li>
        <li><a href='tel:0800 111 9500'><FaPhoneAlt/><span>0800 111 9500</span></a></li>
      </ul>
    </div>
  );
}

export default FooterContact;