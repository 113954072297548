import {Button, Container} from 'react-bootstrap';

function CriarContaSection() {
  return (
    <div className='CriarContaSection'>
      <Container className='d-flex flex-column gap-3 text-center align-items-center justify-content-center pb-0'>
        <h2>Crie sua conta na AgriSwap e tenha mais comodidade!</h2>
        <a href='https://painel.agriswap.com.br/'><Button variant="outline-primary">ACESSAR CONTA</Button></a>
        <a href='https://painel.agriswap.com.br/criar'><Button variant="primary">CRIAR CONTA</Button></a>
      </Container>
    </div>
  )
}

export default CriarContaSection;