import { Button, Container, Row, Col, Image } from "react-bootstrap";

import Navbar from "./Navbar";
import imgHeaderSrc from "../../src/assets/images/header.png";

function Header() {
    return (
      <div className='Header'>
        <Navbar />
        <Container className="ContainerTitle">
          <Row>
            <Col className="MainTitle" xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <h1>Banco Digital do Agricultor</h1>
              <span>Especializado nas necessidades do agronegócio.</span>
              <div className="MainButtons">
                <a href="https://painel.agriswap.com.br/"><Button variant="primary">ACESSAR CONTA</Button></a>
                <a href="https://painel.agriswap.com.br/criar"><Button variant="outline-secondary">CRIAR CONTA</Button></a>
              </div>
            </Col>
            <Col className="d-none d-sm-block d-sm-none d-md-block" xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              <Image fluid src={imgHeaderSrc} alt="Ilustração agricultura"/>
            </Col>
          </Row>
        </Container>
      </div>
    );
}

export default Header;