import {Row, Col} from 'react-bootstrap';


function Cards(props) {
  
  return (
    <Row className="Cards">
      {props.cards.map(({icon, title, description, link, button}) => 
        <Col className="CardItem" xs={12} md={6} lg={4}>
          <div className='CardContent'>
            <div>
              <i class={icon} style={{color: button.color}}></i>
              <h5>{title}</h5>
              <p>{description}</p>
            </div>
            <div>
              <a href={button.link} target='blank' style={{ color: button.color}}><i class="fa-solid fa-arrow-right-long"></i> {button.text}</a>
            </div>
          </div>
        </Col>
      )}
    </Row>
  )
}

export default Cards;