import {Row, Col, Button, Image} from 'react-bootstrap';

import imgBody1Src from "../../src/assets/images/body-1.png";

function SeguroAgricola() {
  return (
    <Row>
      <Col>
        <Image className='mb-5' fluid xs={12} sm={6} src={imgBody1Src} alt="Seguro Agrícola" />
      </Col>
      <Col className='DescriptionText' xs={12} sm={6}>
        <h2 className='mb-4'>Seguro Agrícola</h2>
        <p>Não fique preocupado com as adversidades, possuímos os melhores planos de seguros que contemplam desde de suas máquinas até sua lavoura.</p>
        <a href='https://api.whatsapp.com/send?phone=5565999401158&text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20o%20seguro%20agrícola%20da%20AgriSwap.' target='_blank' rel="noreferrer"><Button className='mt-4'>FALE COM UM CONSULTOR</Button></a>
      </Col>
    </Row>
  )
}

export default SeguroAgricola;