function AgriswapTheme() {
  return (
      <style type="text/css">
        {`
          h1 {
            font-weight: 700;
          }
          .btn-primary {
            background-color: #00903E;
            color: white;
            border-width: 2px;
            border-color: #00903E;
          }
          .btn-primary:hover {
            background: none;
            color: #00903E;
            border-color: #00903E;
          }
          .btn-primary:active {
            background-color: #00662c !important;
            border-color: #00662c !important;
          }
          .btn-outline-primary {
            color: #00903E;
            border-color: #00903E;
            border-width: 2px;
          }
          .btn-outline-primary:hover {
            color: #fff;
            border-color: #00903E;
            background-color: #00903E;
          }
          .btn-outline-primary:active {
            background-color: #00662c !important;
            border-color: #00662c !important;
          }
          .btn-outline-secondary {
            background-color: #fff;
            color: #003990;
            border-width: 2px;
            border-color: #003990;
          }
          .btn-outline-secondary:hover {
            background-color: #003990;
            color: #ffffff;
            border-color: #003990;
          }
          .btn-outline-secondary:active {
            background-color: #002761 !important;
            border-color: #002761 !important;
          }
          .btn {
            border-radius: 50px;
            padding: 10px 24px;
            font-weight: 700;
          }
          a, a:hover {
            text-decoration: none;
            color: unset
          }
          @media only screen and (min-width: 1440px){
            .container {
              width: 1200px;
            }
          }
        `}
      </style>
  );
}

export default AgriswapTheme;