import logoWhiteSrc from '../assets/images/agriswap-white-logo.png';

function FooterDescription() {
  return (
    <div className="FooterDescription">
      <h3>
        <img
          src={logoWhiteSrc}
          alt="AgriSwap logo branco"
          width='100%'
        />
      </h3>
      <p>Somos uma empresa prestadora de serviços de correspondente bancário de outras instituições.</p>
    </div>
  );
}

export default FooterDescription;