import { Row, Col, Container } from 'react-bootstrap';
import Copyright from './Copyright';
import FooterDescription from './FooterDescription';
import FooterContact from './FooterContact';
import FooterOurServices from './FooterOurServices';
import FooterSocialMedias from './FooterSocialMedias';

function Footer() {
  return (
    <div className="Footer">

      <div className="FooterContent">
        <Container fluid="lg">
          <Row>
            <Col sm={6} lg={3} xs={12}>
              <FooterDescription />
            </Col>
            <Col sm={6} lg={3} xs={12}>
              <FooterContact />
            </Col>
            <Col sm={6} lg={3} xs={12}>
              <FooterOurServices />
            </Col>
            <Col sm={6} lg={3} xs={12}>
              <FooterSocialMedias />
            </Col>
          </Row>
        </Container>
      </div>
      <Copyright />

    </div>
  );
}

export default Footer;