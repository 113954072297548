import './assets/styles/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import AgriswapTheme from './assets/styles/AgriswapTheme';
import Header from './components/Header';
import Body   from './components/Body';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <AgriswapTheme />
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default App;