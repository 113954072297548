import {Container} from 'react-bootstrap'

import SeguroAgricola from "./SeguroAgricola";
import CrediarioRapido from "./CrediarioRapido";
import SobMedida from "./SobMedida";

function BodySection() {
  return (
    <Container className='d-flex flex-column main-section'>
      <SeguroAgricola />
      <CrediarioRapido />
      <SobMedida />
    </Container>
  )
}

export default BodySection;