import {Container} from 'react-bootstrap';
import Cards from './Cards';

function NossasFerramentasSection() {
  const cardsContent = [
    {
      icon:'fa-regular fa-user fa-2xl',
      title:'Conta Digital',
      description:'Faça tudo sem sair da sua propriedade, tudo na palma da sua mão.',
      button: {
        text : 'CRIAR CONTA',
        color: '#77C34F',
        link: 'https://painel.agriswap.com.br/criar'
      }
    },
    {
      icon:'fa-solid fa-money-bill-transfer fa-2xl',
      title:'Transferências',
      description:'Transferência gratuita entre contas digitais, Ted Grátis para mesma titularidade, PIX e Qr Code.',
      button: {
        text : 'SAIBA MAIS',
        color: '#507EF9',
        link: 'https://api.whatsapp.com/send?phone=5565999401158&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20transferências%20com%20a%20AgriSwap.'
      }
    },
    {
      icon:'fa-solid fa-cash-register fa-2xl',
      title:'Pagamentos',
      description:'Boleto parcelado em até 12x. Controle de folha salarial, Conta salário.',
      button: {
        text: 'CONFIRA',
        color: '#ff6b6b',
        link: 'https://api.whatsapp.com/send?phone=5565999401158&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20pagamentos%20com%20a%20AgriSwap.'
      }
    },
    {
      icon:'fa-solid fa-file-invoice-dollar fa-2xl',
      title:'Nota Fiscal Rural',
      description:'Emita suas notas fiscais direto da sua propriedade de forma prática, converse com nossa equipe e veja como é fácil.',
      button: {
        text: 'NOSSOS SERVIÇOS',
        color: '#EA53BF',
        link: 'https://api.whatsapp.com/send?phone=5565999401158&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20emiss%C3%A3o%20de%20notas%20fiscais%20com%20a%20AgriSwap.'
      }
    },
    {
      icon:'fa-solid fa-qrcode fa-2xl',
      title:'Recebimentos',
      description:'Receba suas vendas de forma fácil, Maquinha de Pagamentos, Boleto, Link de Pagamento e Qr Code.',
      button: {
        text: 'SAIBA MAIS',
        color: '#F8B946',
        link: 'https://api.whatsapp.com/send?phone=5565999401158&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20recebimentos%20com%20a%20AgriSwap.'
      }
    },
    {
      icon:'fa-solid fa-sack-dollar fa-2xl',
      title:'CashBack',
      description:'Faça suas operações financeiras em nossa plataforma e receba cashback para usar onde quiser.',
      button: {
        text: 'ACESSAR CONTA',
        color: '#5AA33B',
        link: 'https://api.whatsapp.com/send?phone=5565999401158&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20o%20cashback%20da%20AgriSwap.'
      }
    }
  ]
  
  return (
    <div className='NossasFerramentasSection'>
      <Container>
        <h2>Confira todas nossas ferramentas para facilitar a sua vida!</h2>
        <Cards cards={cardsContent}/>
      </Container>
    </div>
  )
}

export default NossasFerramentasSection;