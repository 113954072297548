function FooterOurServices() {
  return (
    <div className="FooterOurServices">
      <h3>Nossos serviços</h3>
      <ul className="list-unstyled">
        <li>Conta Digital</li>
        <li>Notas Fiscais</li>
        <li>Crediário Online</li>
        <li>Pagamentos</li>
        <li>Conta Salário</li>
      </ul>
    </div>
  );
}

export default FooterOurServices;