import BodySection from "./BodySection";
import NossasFerramentasSection from "./NossasFerramentasSection";
import CriarContaSection from "./CriarContaSection";

function Body() {
  return (

      <div className="Body">
        <BodySection />
        <NossasFerramentasSection />
        <CriarContaSection />
      </div>

  );
}

export default Body;