import {Row, Col, Button, Image} from 'react-bootstrap';
import {FaCheck} from 'react-icons/fa';

import imgBody3Src from "../../src/assets/images/body-3.jpeg";

function SobMedida() {
  return (
    <Row>
      <Col className='mb-5' xs={12} sm={6}>
        <Image fluid src={imgBody3Src} alt="Sob Medida" />
      </Col>
      <Col className='DescriptionText' xs={12} sm={6}>
        <h2 className='mb-4'>Feito sob medida para o Produtor Rural</h2>
        <p><FaCheck size={22} color='#00903E'/> Pix, TED, DOC, CashBack</p>
        <p><FaCheck size={22} color='#00903E'/> Gere Boletos, Links de cartão de crédito e Máquininhas</p>
        <p><FaCheck size={22} color='#00903E'/> Folha Salarial e Conta Salário para Funcionários</p>
        <p><FaCheck size={22} color='#00903E'/> Emissão de Notas Fiscais de Produtor Rural</p>
        <a href='https://painel.agriswap.com.br/criar' target='_blank' rel="noreferrer"><Button className='mt-4'>CRIAR CONTA</Button></a>
      </Col>
    </Row>
  )
}

export default SobMedida;