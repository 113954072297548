import { FaInstagram, FaFacebookSquare, FaLinkedin } from "react-icons/fa";

function FooterOurServices() {
  return (
    <div className="FooterOurServices">
      <h3>Siga-nos</h3>
      <div className="SocialMediaIcon">
        <a href="https://www.instagram.com/agriswap.finance/" target="_blank" rel="noreferrer"><FaInstagram size={35}/></a>
        <a href="https://www.facebook.com/pagueagro" target="_blank" rel="noreferrer"><FaFacebookSquare size={35}/></a>
        <a href="https://www.linkedin.com/company/pague-agro/" target="_blank" rel="noreferrer"><FaLinkedin size={35}/></a>
      </div>
    </div>
  );
}

export default FooterOurServices;